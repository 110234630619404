.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
 font-family: "september-spirit";
 src: local("september-spirit"),
  url("./assets/september-spirit/september-spirit.otf") format("truetype");
}
@font-face {
 font-family: "verdana";
 src: local("verdana"),
  url("./assets/fonts/verdana.ttf") format("truetype");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rpv-core__inner-page {
  background-color: rgba($color: #ffffff, $alpha: 0);
}

.core__inner-container {
  height: none !important;
}

.text-2xs {
  font-size: 0.6rem;
  line-height: 0.7rem;
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.pagination {
  display: flex;
  // list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.pagination li.previous a {
  display: flex;
  color: #64748B;
  padding: 4px;
}
.pagination li.previous a:hover {
  background-color: #E8EDFF;
  padding: 4px;
  color: #3762FB;
}
.pagination li.next a {
  display: flex;
  color: #64748B;
  padding: 4px;
}
.pagination li.next a:hover {
  background-color: #E8EDFF;
  padding: 4px;
  color: #3762FB;
}
.pagination li.disabled a {
  cursor: default;
  color:  #64748B;
}
.pagination li.disabled a:hover {
  cursor: default;
  color:  #64748B;
  background-color: transparent;
}
.pagination li.disabled a {
  cursor: default;
  background-color: transparent;
}
.pagination a {
  border-radius: 4px;
  // color:  #64748B;
  text-decoration: none;
  cursor: pointer;
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
}
.pagination a:hover {
  color: #3762FB;
}

.pagination .active {
  background-color: #E8EDFF;
  color: #3762FB;
  border-radius: 4px;
  // font-size: 14px;
  // cursor: default;
}
.step-text {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #64748B;
  margin-top: 8px;
}
.verified-progress-item {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #32D583;
}
.current-progress-item {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #121A2D;
}
.next-progress-item {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #64748B;
}
.checked-circle {
  position: relative;
  display: flex;
  margin-right: 12px;
  height: 18px;
  width: 18px;
  background-color: #32D583;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
}
.unchecked-circle {
  display: flex;
  margin-right: 12px;
  height: 18px;
  width: 18px;
  background-color: #CBD5E1;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
}
.app-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}